import React from "react"

const Divider = ({ fill }) => (
  <div className="w-full" height={2}>
    <svg
      height="2"
      preserveAspectRatio="none"
      viewBox="0 0 1440 2"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill || "#f22e52"} fillRule="evenodd">
        <path d="m39.6 0h33.44v1.76h-33.44z" />
        <path d="m14.96 0h16.72v1.76h-16.72z" />
        <path d="m0 0h8.8v1.76h-8.8z" />
        <path d="m79.2 0h66v1.76h-66z" />
        <path d="m153.12 0h132.88v1.76h-132.88z" />
        <path d="m293.92 0h264.88v1.76h-264.88z" />
        <path d="m567 0h529.76v1.76h-529.76z" />
        <path d="m1105 0h529.76v1.76h-529.76z" />
      </g>
    </svg>
  </div>
)

export default Divider

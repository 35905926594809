import tw, { styled } from "twin.macro"
const BasicPageContent = styled.div`
  ${tw`font-body text-base text-grey-10 pb-16`}
  font-size: 15px;
  line-height: 30px;
  @media (min-width: 640px) {
    font-size: 17px;
    line-height: 34px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`font-display`}
  }

  h1 {
    ${tw`text-2xl py-8`}
  }

  h2 {
    ${tw`text-xl leading-relaxed`}
  }

  p,
  li {
    ${tw`mb-4`}
  }

  a {
    ${tw`text-dodgeblue`}
    &:hover {
      text-decoration: underline;
    }
  }

  ol {
    ${tw`pl-8`}
    list-style-type: lower-alpha;

    ol {
      list-style-type: lower-roman;
    }
  }

  ul {
    ${tw`pl-8 list-disc`}
  }

  h5 {
    ${tw`font-display text-grey-15`}
    display: block;
    font-size: 21px;
    line-height: 28px;
    @media (min-width: 640px) {
      font-size: 26px;
      line-height: 32px;
    }
    margin-bottom: 32px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 80px;
  }

  b,
  strong {
    ${tw`font-display`}
  }
`

export default BasicPageContent

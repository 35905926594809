import React from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from 'twin.macro'

import { brandBlock } from "utils/brand"
import Layout from "components/layout"
import SEO from "components/seo"
import Divider from "components/divider"
import BasicPageContent from "components/basic-page-content"

import { getSharePreviewUrls } from "utils/share-preview"

const Header = styled.div`
  ${tw`pt-20 m:pt-32 flex flex-wrap`}
  background: linear-gradient(337.38deg, #2b1143 0%, #000000 100%);
`

const PostTitle = styled.h1`
  ${tw`py-2 pb-8 w-full font-display font-bold text-white`}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 62px;
  }
`

const Main = styled.div`
  img {
    padding-top: 1rem;
    padding-bottom: 1rem;
    ${tw`w-screen mx-auto`}
  }
`

export default ({ data }) => {
  const page = brandBlock(data.gcms.page)

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <Header>
        <div className="px-2 m:pl-10 pb-10 w-full text-center">
          <PostTitle
            dangerouslySetInnerHTML={{
              __html: page?.title || "Terms of Service",
            }}
          />
        </div>
      </Header>
      <Divider />
      <div className="py-10 l:pt-10 bg-white">
        <div className="max-w-s mx-auto m:max-w-m l:max-w-l flex flex-row">
          <div className="flex-shrink-0 w-1/6 hidden l:block"></div>
          <Main id="content">
            <BasicPageContent
              dangerouslySetInnerHTML={{ __html: page?.body?.html }}
            />
          </Main>
          <div className="flex-shrink-0 w-1/6 hidden l:block"></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/terms-of-service" }) {
        ...PageData
      }
    }
  }
`

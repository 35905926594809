/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { brandRules } from "utils/brand"

function SEO({ description, lang, meta, title, path, sharePreviewUrls = {} }) {
  const { site, gcms } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        gcms {
          sharePreviewDefaults: websiteSetting(
            where: { name: "Social Share Preview Defaults" }
          ) {
            jsonValue
          }
        }
      }
    `
  )

  const sharePreviewDefaults = gcms.sharePreviewDefaults?.jsonValue || {}

  const metaDescription = brandRules(
    description || site.siteMetadata.description,
    false
  )
  const defaultMeta =
    !meta || !meta.find((m) => m.property === "og:type")
      ? [
          {
            property: `og:type`,
            content: `website`,
          },
        ]
      : []

  const defaultLink = []

  if (path) {
    const pageUrl = `${site.siteMetadata.siteUrl}${path}`
    defaultMeta.push({
      property: "og:url",
      content: pageUrl,
    })
    defaultLink.push({
      rel: "canonical",
      href: pageUrl,
    })
  }

  const metaImages = [
    {
      property: "og:image",
      content: sharePreviewUrls.social || sharePreviewDefaults.social,
    },
    {
      name: "twitter:image",
      content: sharePreviewUrls.twitter || sharePreviewDefaults.twitter,
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={brandRules(title, false)}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={defaultLink}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: "Parrot Analytics",
        },
        {
          property: `og:locale`,
          content: "en_US",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:site`,
          content: "@ParrotAnalytics",
        },
      ]
        .concat(meta)
        .concat(defaultMeta)
        .concat(metaImages)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
